<template>
  <div>
    <div id="floating-panel">
      <b>Start: </b>
      <select
        id="start"
        @change="calculateAndDisplayRoute"
      >
        <option value="penn station, new york, ny">
          Penn Station
        </option>
        <option value="grand central station, new york, ny">
          Grand Central Station
        </option>
        <option value="625 8th Avenue, New York, NY, 10018">
          Port Authority Bus Terminal
        </option>
        <option value="staten island ferry terminal, new york, ny">
          Staten Island Ferry Terminal
        </option>
        <option value="101 E 125th Street, New York, NY">
          Harlem - 125th St Station
        </option>
      </select>
      <b>End: </b>
      <select
        id="end"
        @change="calculateAndDisplayRoute"
      >
        <option value="260 Broadway New York NY 10007">
          City Hall
        </option>
        <option value="W 49th St & 5th Ave, New York, NY 10020">
          Rockefeller Center
        </option>
        <option value="moma, New York, NY">
          MOMA
        </option>
        <option value="350 5th Ave, New York, NY, 10118">
          Empire State Building
        </option>
        <option value="253 West 125th Street, New York, NY">
          Apollo Theater
        </option>
        <option value="1 Wall St, New York, NY">
          Wall St
        </option>
      </select>
    </div>
    <div
      id="map"
      ref="map"
    />
    &nbsp;
    <div id="warnings-panel" />
  </div>
</template>
<script>
import gmapsInit from '@/components/map/gmaps'

export default {
  data() {
    return {
      markerArray: [],
      directionsService: null,
      map: null,
      directionsRenderer: null,
      stepDisplay: null,
      user: null,
      watchPositionId: null,
      g: null,
      lat: 0,
      lng: 0,
      data: {
        closetime: '5:00 pm',
      },
    }
  },
  async mounted() {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      'https://polyfill.io/v3/polyfill.min.js?features=default',
    )
    plugin.async = true
    document.head.appendChild(plugin)

    this.g = await gmapsInit()
    this.initMap()
  },
  methods: {
    initMap() {
      this.markerArray = []
      // Instantiate a directions service.
      this.directionsService = new google.maps.DirectionsService()
      // Create a map and center it on Manhattan.
      this.map = new google.maps.Map(this.$refs.map, {
        zoom: 13,
        center: { lat: 40.771, lng: -73.974 },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      })
      // Create a renderer for directions and bind it to the map.
      this.directionsRenderer = new google.maps.DirectionsRenderer({ map })
      // Instantiate an info window to hold step text.
      this.stepDisplay = new google.maps.InfoWindow()

      // Display the route between the initial start and end selections.
      this.calculateAndDisplayRoute()
    },

    calculateAndDisplayRoute() {
      // First, remove any existing markers from the map.
      for (let i = 0; i < this.markerArray.length; i++) {
        this.markerArray[i].setMap(null)
      }

      // Retrieve the start and end locations and create a DirectionsRequest using
      // WALKING directions.
      this.directionsService
        .route({
          origin: document.getElementById('start').value,
          destination: document.getElementById('end').value,
          travelMode: google.maps.TravelMode.WALKING,
        })
        .then(result => {
          // Route the directions and pass the response to a function to create
          // markers for each step.
          document.getElementById('warnings-panel').innerHTML = `<b>${result.routes[0].warnings}</b>`
          this.directionsRenderer.setDirections(result)
          this.showSteps(result)
        })
        .catch(e => {
          console.log(e)
          window.alert(`Directions request failed due to ${e}`)
        })
    },

    showSteps(directionResult) {
      // For each step, place a marker, and add the text to the marker's infowindow.
      // Also attach the marker to an array so we can keep track of it and remove it
      // when calculating new routes.
      const myRoute = directionResult.routes[0].legs[0]
      for (let i = 0; i < myRoute.steps.length; i++) {
        const marker = (this.markerArray[i] = this.markerArray[i] || new google.maps.Marker())
        marker.setMap(this.map)
        marker.setPosition(myRoute.steps[i].start_location)
        this.attachInstructionText(
          this.stepDisplay,
          marker,
          myRoute.steps[i].instructions,
          this.map,
        )
      }
    },

    attachInstructionText(stepDisplay, marker, text, map) {
      console.log('attaching instructions')
      google.maps.event.addListener(marker, 'click', () => {
        // Open an info window when the marker is clicked on, containing the text
        // of the step.
        stepDisplay.setContent(text)
        stepDisplay.open(map, marker)
      })
    },
  },
}
</script>

<style scoped>
/* [START maps_directions_complex] */
/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
#map {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

#floating-panelx {
  position: absolute;
  top: 10px;
  left: 25%;
  z-index: 5;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #999;
  text-align: center;
  font-family: "Roboto", "sans-serif";
  line-height: 30px;
  padding-left: 10px;
}

#warnings-panel {
  width: 100%;
  height: 10%;
  text-align: center;
}

/* [END maps_directions_complex] */
</style>
